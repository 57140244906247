export const calculateMeanTemp = (array) => {
    const result = array.reduce((acc, value) => {
        if (value > 0 && value < 30) {
            acc.sum += value;
            acc.count += 1;
        }
        return acc;
    }, { sum: 0, count: 0 });

    if (result.count === 0) {
        return '0.00';
    }

    const mean = result.sum / result.count;
    return mean !== undefined ? mean.toFixed(2) : 0.00;
};

export const calculateAverageAnnual = (dataArray) => {
    let yearlyData = 0;
    let yearsCount = 0;
    let totalData = 0;

    dataArray.forEach((value, index) => {
        totalData += value;

        if ((index + 1) % 365 === 0) {
            yearlyData += totalData;
            yearsCount++;
            totalData = 0;
        }
    });

    const annualAverage = yearsCount !== 0 ? yearlyData / yearsCount : 0;
    return annualAverage !== undefined ? annualAverage.toFixed(2) : 0.00;
};