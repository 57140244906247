import React, { useState, useEffect } from "react";
import axios from 'axios';
import './WeatherForm.css';
import Footer from "../footer/footer";
import { findLifeZone } from "../../utils/holdridgeUtils";
import { calculateAverageAnnual, calculateMeanTemp } from "./calculateWeatherUtils";
import { apiURLBase, localServerURL, stagingServerURL } from "../../config";
import MapDisplay from "../map/mapDisplay";
import InputForm from "./InputForm";
import ResponsiveLineGraph from "./ResponsiveLineGraph";
import { convertOutputToDataset } from "./createGraphUtils";
import InputCSVButton from "../buttons/InputCSVButton";
import ResultsCSVButton from "../buttons/ResultsCSVButton";


const WeatherForm = () => {
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [averagePrecipitation, setAveragePrecipitation] = useState(null);
    const [averagePET, setAveragePET] = useState(null);
    const [temperatureMean, setTemperatureMean] = useState(null);
    const [polygonArea, setPolygonArea] = useState(0);
    const [centerPolygon, setCenterPolygon] = useState([0, 0]);
    const [ageOfForest, setAgeOfForest] = useState(0);
    const [forestType, setForestType] = useState('');
    const [agricultureMethod, setAgricultureMethod] = useState('');
    const [percentageLandUsed, setPercentageLandUsed] = useState(30);
    const [yearsOfData, setYearsOfData] = useState(2);
    const [sequesteringStat, setSequesteringStat] = useState(50);
    const [holdridgeZone, setHoldridgeZone] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [monoMain, setMonoMain] = useState('');
    const [monoRelief, setMonoRelief] = useState('');
    const [tieredFloor, setTieredFloor] = useState('');
    const [tieredUnderstory, setTieredUnderstory] = useState('');
    const [tieredCanopy, setTieredCanopy] = useState('');
    const [orchardTree, setOrchardTree] = useState('');
    const [orchardFloor, setOrchardFloor] = useState('');
    const [treeStand, setTreeStand] = useState('');
    const [areaCoordinates, setAreaCoordinates] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [chartDatasets, setChartDatasets] = useState([]);

    const PETratio = averagePET && averagePrecipitation && temperatureMean ? (averagePET / averagePrecipitation).toFixed(2) : null;

    const handleLatitudeChange = (event) => {
        const value = parseFloat(event.target.value);
        setLatitude(isNaN(value) ? 0 : value);
    };

    const handleLongitudeChange = (event) => {
        const value = parseFloat(event.target.value);
        setLongitude(isNaN(value) ? 0 : value);
    };

    const JSONobject = {
        date_submitted: Date.now,
        years_of_data_req: yearsOfData,
        location: {
            latitude: latitude,
            longitude: longitude,
            forest: {
                forest_age: ageOfForest,
                primary_or_secondary: forestType,
                sequestering_rate: (sequesteringStat / 100),
            },
            agriculture: {
                agriculture_method: agricultureMethod,
                mono_crop: {
                    main_crop: monoMain,
                    relief_crop: monoRelief
                },
                tiered: {
                    floor: tieredFloor,
                    understory: tieredUnderstory,
                    canopy: tieredCanopy
                },
                orchard: {
                    tree: orchardTree,
                    floor: orchardFloor
                },
                tree_stand: {
                    trees: treeStand
                },
                percent_area_agriculture: percentageLandUsed,
                total_envelope_ha: polygonArea,
            },
            weather: {
                PET: averagePET,
                annual_precip: averagePrecipitation,
                bio_temp: temperatureMean,
                holdridge_zone: holdridgeZone,
            },
            area_coordinates: {
                coordinates: areaCoordinates
            }

        }
    };

    useEffect(() => {
        setHoldridgeZone(findLifeZone(PETratio, averagePrecipitation, temperatureMean));
    }, [averagePET, averagePrecipitation, temperatureMean]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (areaCoordinates.length !== 0) {
            await axios.get(apiURLBase + `?latitude=${latitude}&longitude=${longitude}&start_date=2010-01-01&end_date=2022-01-01&daily=temperature_2m_mean,precipitation_sum,et0_fao_evapotranspiration&timezone=auto`)
                .then((response) => {
                    const { temperature_2m_mean, precipitation_sum, et0_fao_evapotranspiration } = response.data.daily;
                    const meanTemp = calculateMeanTemp(temperature_2m_mean);
                    const petAverage = calculateAverageAnnual(et0_fao_evapotranspiration);
                    const precipitationAverage = calculateAverageAnnual(precipitation_sum);

                    setTemperatureMean(meanTemp);
                    setAveragePrecipitation(precipitationAverage);
                    setAveragePET(petAverage);

                    const newHoldridgeZone = findLifeZone((petAverage / precipitationAverage).toFixed(2), precipitationAverage, meanTemp);
                    setHoldridgeZone(newHoldridgeZone);
                    setSubmitClicked(true);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        else alert('Please draw a polygon on the map to submit data');
    };

    useEffect(() => {
        console.log(JSONobject);
        // Function to perform the POST request
        if (submitClicked === true) {
            setSubmitClicked(false);
            const postData = async () => {
                try {
                    const response = await axios.post(stagingServerURL, JSONobject);
                    const responseDatas = response.data.data;
                    setResponseData(responseDatas);

                } catch (error) {
                    if (error.response && error.response.status === 405) {
                        alert(`Agriculture data for this area is not currently available`);
                    }
                    if (error.response && error.response.status === 403) {
                        alert(`Please select the crops`);
                    }
                }
            }
            postData();
        }
    }, [submitClicked]);

    useEffect(() => {
        if (responseData.length !== 0) {
            setChartDatasets(convertOutputToDataset(responseData));
        }
    }, [responseData]);

    useEffect(() => {
        if (centerPolygon && centerPolygon.length === 2 &&
            typeof centerPolygon[0] === 'number' && typeof centerPolygon[1] === 'number') {
            setLatitude(centerPolygon[1]);
            setLongitude(centerPolygon[0]);
        }
    }, [centerPolygon]);

    useEffect(() => {
        setMonoMain('');
        setMonoRelief('');
        setTieredFloor('');
        setTieredUnderstory('');
        setTieredCanopy('');
        setOrchardTree('');
        setOrchardFloor('');
        setTreeStand('');
    }, [agricultureMethod])

    const renderCropData = (crop) => {
        let dataToRender = chartDatasets[crop];
        // Combine datasets for plantation_carbon and carbon
        if (crop === 'plantation_carbon' || crop === 'carbon') {
            dataToRender = [
                ...chartDatasets['plantation_carbon'],
                ...chartDatasets['carbon']
            ];
        }

        const yValues = dataToRender?.flatMap(dataset => dataset.data.map(d => d.y));
        const maxValue = Math.max(...yValues);

        if (crop === "credits") {
            const credit_data = chartDatasets[crop];
            const credits = credit_data[0].data[1].y;
            return (
                <>
                    <div className="d-flex mt-4">
                        <InputCSVButton
                            inputData={JSONobject}
                        />
                        <ResultsCSVButton
                            responseData={responseData}
                        />
                    </div>
                    <div className="row d-flex justify-content-start">
                        <div className="card col-4 mt-3">
                            <div className="card-body">
                                <div className="mt-1">
                                    <p className='holdridge-info'>Potential Carbon Credits </p>
                                    <p className='holdridge-values'>{credits}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (crop !== 'carbon' && crop !== 'plantation_carbon') {
            return (
                <>
                    <div className="row">
                        <div key={crop} className="card col-10 mt-3 results-card px-0">
                            <div className="card-header">{crop.charAt(0).toUpperCase() + crop.slice(1)} produced</div>
                            <ResponsiveLineGraph
                                data={chartDatasets[crop]}
                                minHeight={'0'}
                                maxHeight={maxValue * 1.1}
                                yAxisLegend='produce (tonnes)'
                            />
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div className="row">
                    <div key={crop} className="card col-10 mt-3 results-card px-0">
                        <div className="card-header">
                            Carbon Statistics
                        </div>
                        <ResponsiveLineGraph
                            data={dataToRender}
                            minHeight={'0'}
                            maxHeight={maxValue * 1.1}
                            yAxisLegend='Mass of Carbon'
                        />
                    </div>
                </div>
            );
        }
    }


    return (
        <>
            <div className="row mx-3" id="map-container">
                <MapDisplay
                    latitude={latitude}
                    longitude={longitude}
                    setCenterPolygon={setCenterPolygon}
                    setPolygonArea={setPolygonArea}
                    polygonArea={polygonArea}
                    setAreaCoordinates={setAreaCoordinates}
                />
                <InputForm
                    latitude={latitude}
                    handleLatitudeChange={handleLatitudeChange}
                    longitude={longitude}
                    handleLongitudeChange={handleLongitudeChange}
                    ageOfForest={ageOfForest}
                    setAgeOfForest={setAgeOfForest}
                    forestType={forestType}
                    setForestType={setForestType}
                    agricultureMethod={agricultureMethod}
                    setAgricultureMethod={setAgricultureMethod}
                    monoMain={monoMain}
                    setMonoMain={setMonoMain}
                    monoRelief={monoRelief}
                    setMonoRelief={setMonoRelief}
                    tieredFloor={tieredFloor}
                    setTieredFloor={setTieredFloor}
                    tieredUnderstory={tieredUnderstory}
                    setTieredUnderstory={setTieredUnderstory}
                    tieredCanopy={tieredCanopy}
                    setTieredCanopy={setTieredCanopy}
                    orchardTree={orchardTree}
                    setOrchardTree={setOrchardTree}
                    orchardFloor={orchardFloor}
                    setOrchardFloor={setOrchardFloor}
                    treeStand={treeStand}
                    setTreeStand={setTreeStand}
                    percentageLandUsed={percentageLandUsed}
                    setPercentageLandUsed={setPercentageLandUsed}
                    yearsOfData={yearsOfData}
                    setYearsOfData={setYearsOfData}
                    sequesteringStat={sequesteringStat}
                    setSequesteringStat={setSequesteringStat}
                    handleSubmit={handleSubmit}
                />
                <div className="d-flex justify-content-between">
                    <div className="card col-3 mt-3">
                        <div className="card-body">
                            <div className="mt-1">
                                <p className='holdridge-info'>Biotemperature</p>
                                <p className='holdridge-values'>{temperatureMean}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card col-3 mx-1 mt-3">
                        <div className="card-body">
                            <div className="mt-1">
                                <p className='holdridge-info'>Annual Precipitation</p>
                                <p className='holdridge-values'>{averagePrecipitation ? averagePrecipitation : ''}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card col-3 mt-3">
                        <div className="card-body">
                            <div className="mt-1">
                                <p className='holdridge-info'>PET ratio </p>
                                <p className='holdridge-values'>{isNaN(PETratio) ? '' : PETratio}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card col-3 mx-1 mt-3">
                        <div className="card-body">
                            <div className="mt-1">
                                <p className='holdridge-info'>Holdridge Zone </p>
                                <p className='holdridge-values'>{holdridgeZone}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {chartDatasets && Object.keys(chartDatasets).filter(crop => crop !== 'carbon').map(crop => renderCropData(crop))}
            </div>
            <Footer />
        </>
    );
};

export default WeatherForm;
