import React from "react";
import { formatDateForFilename } from "./CSVUtils";

// const data = {
//     date_submitted: "2021-03-31T18:00:00.000Z",
//     years_of_data_req: '20',
//     location: {
//         agriculture: {
//             agriculture_method: "mono_crop",
//             mono_crop: {
//                 main_crop: "Sugarcane",
//                 relief_crop: ""
//             },
//             orchard: {
//                 floor: "",
//                 tree: ""
//             },
//             percent_area_agriculture: 30,
//             tiered: {
//                 canopy: "",
//                 floor: "",
//                 understory: ""
//             },
//             total_envelope_ha: 27288.421064,
//             tree_stand: {
//                 trees: ""
//             }
//         },
//         area_coordinates: {
//             coordinates: [
//                 [-83.02649568092492, 9.206190118801942],
//                 [-82.83635643405152, 9.293321159603437],
//                 [-82.83635643405152, 9.058688756228293],
//                 [-83.02649568092492, 9.206190118801942]
//             ]
//         },
//         forest: {
//             forest_age: "2",
//             primary_or_secondary: "primary",
//             sequestering_rate: 0
//         },
//         latitude: 9.176004957915865,
//         longitude: -82.93142605748822,
//         weather: {
//             PET: "1043.29",
//             annual_precip: "3065.51",
//             bio_temp: "15.83",
//             holdridge_zone: ["Premontane atlantic wet forest"]
//         }
//     }
// };

const InputCSVButton = ({ inputData }) => {
    const convertToCSV = (data) => {
        let csvRows = [];

        const headers = [
            'date_submitted',
            'years_of_data_req',
            'agriculture_method',
            'crop1',
            'crop2',
            'crop3',
            'total_area_ha',
            'forest_age',
            'sequestering_rate',
            'primary_or_secondary',
            'percent_area_agriculture',
            'center_latitude',
            'center_longitude',
            'holdridge_zone',
            'area_latitude_1',
            'area_longitude_1',
            'area_latitude_2',
            'area_longitude_2',
            'area_latitude_3',
            'area_longitude_3',
            'area_latitude_4',
            'area_longitude_4',
            'area_latitude_5',
            'area_longitude_5',
            'area_latitude_6',
            'area_longitude_6',
            'area_latitude_7',
            'area_longitude_7',
            'area_latitude_8',
            'area_longitude_8',
            'area_latitude_9',
            'area_longitude_9',
            'area_latitude_10',
            'area_longitude_10',
        ];
        csvRows.push(headers.join(','));

        const getCrops = (data) => {
            if (data.mono_crop.main_crop !== '') {
                return (`${data.mono_crop.main_crop},${data.mono_crop.relief_crop},`);
            }
            else if (data.orchard.tree !== '') {
                return (`${data.orchard.tree},${data.orchard.floor},`);
            }
            else if (data.tiered.canopy !== '' || data.tiered.floor !== '' || data.tiered.understory !== '') {
                return (`${data.tiered.floor}, ${data.tiered.understory}, ${data.tiered.canopy}`);
            }
            else if (data.tree_stand.trees !== '') {
                return (`${data.tree_stand.trees},,`);
            }
            else {
                return (',,,');
            }
        }

        const getCoordinates = (data) => {
            const coordinates = data.area_coordinates.coordinates;
            const flattenedCoordinates = coordinates.reduce((acc, coordinate) => {
                if (coordinate && coordinate.length === 2) {
                    acc.push(coordinate[0], coordinate[1]);
                }
                return acc;
            }, []).join(',');

            return flattenedCoordinates;
        }

        const values = [
            Date(data.date_submitted),
            data.years_of_data_req,
            data.location.agriculture.agriculture_method,
            getCrops(data.location.agriculture),
            data.location.agriculture.total_envelope_ha,
            data.location?.forest?.forest_age || 0,
            data.location?.forest.sequestering_rate,
            data.location?.forest.primary_or_secondary,
            data.location.agriculture.percent_area_agriculture,
            data.location.latitude,
            data.location.longitude,
            data.location?.weather.holdridge_zone[0],
            getCoordinates(data.location)

        ];
        csvRows.push(values.join(','));

        return csvRows.join('\n');
    }

    const downloadCSV = () => {
        const csvData = convertToCSV(inputData);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `kxmercury_input_${formatDateForFilename()}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <>
            <button
                className="btn btn-primary"
                onClick={downloadCSV}
            >
                Download Input CSV
            </button>
        </>
    )
};

export default InputCSVButton;