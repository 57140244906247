import React, { useEffect, useRef } from "react";
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import * as turf from '@turf/turf';


mapboxgl.accessToken = 'pk.eyJ1Ijoic21pdGhuaWMiLCJhIjoiY2xqZWkxZmc5MGFvMDNvb3Z4NmVzazY1MCJ9.MwMR-YifX0FXXWrIqr_V_A';

const Map = ({ setPolygonArea, setCoordinates, setAreaCoordinates }) => {
    const mapContainerRef = useRef(null);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/satellite-v9',
            center: [-84, 10],
            zoom: 6,
        });

        map.on('load', function () {
            const draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true
                },
                defaultMode: 'draw_polygon'
            });

            map.addControl(
                new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true
                    },
                    // When active the map will receive updates to the device's location as it changes.
                    trackUserLocation: true,
                    // Draw an arrow next to the location dot to indicate which direction the device is heading.
                    showUserHeading: true
                })
            );

            map.addControl(draw);

            // Setup draw event listeners
            map.on('draw.create', () => {
                // Check if more than one polygon has been drawn
                if (draw.getAll().features.length > 1) {
                    // Get all but the most recently drawn polygon
                    let allButLatest = draw.getAll().features.slice(0, -1);

                    // Delete each of these polygons
                    allButLatest.forEach(feature => {
                        draw.delete(feature.id);
                    });
                }

                // Continue with area update as before
                updateArea();
            });
            map.on('draw.delete', updateArea);
            map.on('draw.update', updateArea);

            function updateArea() {
                const data = draw.getAll();
                if (data.features.length > 0) {
                    const area = turf.area(data);
                    const roundedArea = Math.round(area * 100) / 100;
                    setPolygonArea(roundedArea / 10000);

                    const center = turf.center(data);
                    map.flyTo({ center: center.geometry.coordinates });
                    setCoordinates(center.geometry.coordinates);

                    // get the coordinates of the polygon
                    const polygonCoordinates = data.features[0].geometry.coordinates[0]; // for first polygon
                    setAreaCoordinates(polygonCoordinates);
                } else {
                    setPolygonArea(0);
                }
            };
        });

        // Cleanup function to remove map on unmount
        return () => map.remove();
    }, [setPolygonArea, setCoordinates]);

    return <div ref={mapContainerRef} style={{ height: '900px' }} />;
};

export default Map;
