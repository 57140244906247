const generateDataForKey = (keyName, outputData) => {
    return {
        "id": keyName,
        "color": generateColorForKey(keyName),
        "data": outputData.years.map((year, index) => ({
            x: year,
            y: outputData[keyName][index]
        }))
    };
};

const generateColorForKey = (keyName) => {
    let hash = 0;
    for (let i = 0; i < keyName.length; i++) {
        hash = keyName.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 50%)`;
};

export const convertOutputToDataset = (outputData) => {
    const excludedKeys = ["years"];
    let datasets = {};

    for (const keyName of Object.keys(outputData)) {
        if (!excludedKeys.includes(keyName)) {
            if (keyName === "baseline" || keyName === "carbon sequestered") {
                // For Carbon data
                if (!datasets.carbon) datasets.carbon = [];
                datasets.carbon.push(generateDataForKey(keyName, outputData));
            } else {
                // For Crop data
                const cropName = keyName.split(" ")[0];  // 'sugarcane', 'peach palm', 'cacao', etc.
                if (!datasets[cropName]) datasets[cropName] = [];
                datasets[cropName].push(generateDataForKey(keyName, outputData));
            }
        }
    }

    return datasets;
};
