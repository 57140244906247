import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import WeatherModelPage from './components/pages/WeatherModelPage';
import LoginPage from './components/pages/LoginPage';
import Root from './components/pages/Root';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <WeatherModelPage />
      },
      {
        path: '/login',
        element: <LoginPage />
      }
    ]
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
