import React from "react";
import { formatDateForFilename } from "./CSVUtils";

const ResultsCSVButton = ({ responseData }) => {

    const convertToCSV = (data) => {
        let csvContent = "";

        // Extract keys (column headers)f
        const headers = Object.keys(data);
        csvContent += headers.join(",") + "\r\n";

        // For simplicity, we're assuming all arrays have the same length
        for (let i = 0; i < data[headers[0]].length; i++) {
            let row = [];
            for (let header of headers) {
                row.push(data[header][i]);
            }
            csvContent += row.join(",") + "\r\n";
        }

        return csvContent;
    }

    const downloadCSV = () => {
        const csvData = convertToCSV(responseData);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `kxmercury_results_${formatDateForFilename()}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <>
            <button
                className="btn btn-primary mx-2"
                onClick={downloadCSV}
            >
                Download Results CSV
            </button>
        </>
    )
};

export default ResultsCSVButton;