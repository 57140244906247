import React from "react";
import './footer.css';
const Footer = () => {
    return (
        <>
            <div className="footer bg-dark py-3 mt-auto">
                <span className="text-secondary mx-4"> &copy; Karbon-X 2023</span>
            </div>
        </>
    );
};

export default Footer;