import React from "react";
import { monoCropDict, orchardCropDict, tierCropDict, treeStandDict } from "../../data/cropData";
import './InputForm.css';

const InputForm = ({
    latitude, handleLatitudeChange,
    longitude, handleLongitudeChange,
    ageOfForest, setAgeOfForest,
    forestType, setForestType,
    agricultureMethod, setAgricultureMethod,
    monoMain, setMonoMain,
    monoRelief, setMonoRelief,
    tieredFloor, setTieredFloor,
    tieredUnderstory, setTieredUnderstory,
    tieredCanopy, setTieredCanopy,
    orchardTree, setOrchardTree,
    orchardFloor, setOrchardFloor,
    treeStand, setTreeStand,
    percentageLandUsed, setPercentageLandUsed,
    yearsOfData, setYearsOfData,
    sequesteringStat, setSequesteringStat,
    handleSubmit }) => {
    const [wasValidated, setWasValidated] = React.useState(false);
    const formRef = React.useRef(null);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const form = formRef.current;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setWasValidated(true);
        } else {
            handleSubmit(e);
        }
    };
    return (
        <>
            <div className="col-12 col-lg-3">
                <div className="card mt-3">
                    <div className="card-header">Select your location</div>
                    <div className="card-body">
                        <form ref={formRef} onSubmit={handleFormSubmit} className={`needs-validation ${wasValidated ? 'was-validated' : ''}`} noValidate>
                            <div className="row">
                                <div className="mb-3 col-6">
                                    <label htmlFor="latitude" className="form-label">
                                        Latitude
                                    </label>
                                    <input
                                        type="number"
                                        step="0.001"
                                        className="form-control"
                                        id="latitude"
                                        value={latitude}
                                        onChange={handleLatitudeChange}
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid latitude.
                                    </div>
                                </div>
                                <div className="mb-3 col-6">
                                    <label htmlFor="longitude" className="form-label">
                                        Longitude
                                    </label>
                                    <input
                                        type="number"
                                        step="0.001"
                                        className="form-control"
                                        id="longitude"
                                        value={longitude}
                                        onChange={handleLongitudeChange}
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid latitude.
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="mb-3 col-12">
                                <label htmlFor="forestType" className="form-label">
                                    Primary or Secondary forest
                                </label>
                                <select
                                    className="form-control"
                                    id="forestType"
                                    value={forestType}
                                    onChange={(e) => setForestType(e.target.value)}
                                    required
                                >
                                    <option value="">--Please choose an option--</option>
                                    <option value="primary">Primary</option>
                                    <option value="secondary">Secondary</option>
                                </select>
                            </div>
                            <hr />
                            <div className="col-12">
                                <label htmlFor="ageOfForest" className="form-label">
                                    Age of forest in years
                                </label>
                            </div>
                            <div className="mb-3 col-5">
                                <input
                                    type="number"
                                    step="1"
                                    min={0}
                                    className="form-control"
                                    id="ageOfForest"
                                    value={ageOfForest}
                                    onChange={(e) => setAgeOfForest(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Choose 0 if unknown
                                </div>
                            </div>
                            <hr />
                            <div className="mb-3 col-12">
                                <label htmlFor="agricultureMethod" className="form-label">
                                    Agriculture Method
                                </label>
                                <select
                                    className="form-control"
                                    id="agricultureMethod"
                                    value={agricultureMethod}
                                    onChange={(e) => setAgricultureMethod(e.target.value)}
                                    required
                                >
                                    <option value="">--Please choose an option--</option>
                                    <option value="mono_crop">Mono-crop</option>
                                    <option value="tiered">Tiered</option>
                                    <option value="orchard">Orchard</option>
                                    <option value="tree_stand">Tree Stand</option>
                                </select>
                            </div>

                            {agricultureMethod === "mono_crop" && (
                                <>
                                    <label htmlFor="mono_main" className="form-label">Main crop</label>
                                    <select
                                        className="form-control"
                                        id="mono_main"
                                        value={monoMain}
                                        onChange={(e) => setMonoMain(e.target.value)}
                                    // required
                                    >
                                        {/* <option
                                            value=""
                                            disabled>Select an option...
                                        </option> */}
                                        {monoCropDict[0].value.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>

                                    <label htmlFor="mono_relief" className="form-label">Relief crop (optional)</label>
                                    <select
                                        className="form-control"
                                        id="mono_relief"
                                        value={monoRelief}
                                        onChange={(e) => setMonoRelief(e.target.value)}
                                    // required
                                    >
                                        {/* <option
                                            value=""
                                            disabled>Select an option...
                                        </option> */}
                                        {monoCropDict[1].value.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </>
                            )}

                            {agricultureMethod === "tiered" && (
                                <>
                                    <label htmlFor="tiered_floor" className="form-label">Floor plant</label>
                                    <select className="form-control"
                                        id="tiered_floor" value={tieredFloor}
                                        onChange={(e) => setTieredFloor(e.target.value)}
                                    // required
                                    >
                                        {/* <option
                                            value=""
                                            disabled>Select an option...
                                        </option> */}
                                        {tierCropDict[1].value.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>


                                    <label htmlFor="tiered_understory" className="form-label">Understory plant</label>
                                    <select
                                        className="form-control"
                                        id="tiered_understory"
                                        value={tieredUnderstory}
                                        onChange={(e) => setTieredUnderstory(e.target.value)}
                                    >
                                        {tierCropDict[0].value.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>


                                    <label htmlFor="tiered_canopy" className="form-label">Canopy plant</label>
                                    <select
                                        className="form-control"
                                        id="tiered_canopy"
                                        value={tieredCanopy}
                                        onChange={(e) => setTieredCanopy(e.target.value)}
                                    >
                                        {tierCropDict[2].value.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </>
                            )}

                            {agricultureMethod === "orchard" && (
                                <>
                                    <label htmlFor="orchard_tree" className="form-label">Fruit tree</label>
                                    <select
                                        className="form-control"
                                        id="orchard_tree"
                                        value={orchardTree}
                                        onChange={(e) => setOrchardTree(e.target.value)}
                                    >
                                        {orchardCropDict[0].value.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>

                                    <label htmlFor="orchard_floor" className="form-label">Floor plant (optional)</label>
                                    <select
                                        className="form-control"
                                        id="orchard_floor"
                                        value={orchardFloor}
                                        onChange={(e) => setOrchardFloor(e.target.value)}
                                    >
                                        {orchardCropDict[1].value.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </>
                            )}

                            {agricultureMethod === "tree_stand" && (
                                <>
                                    <label htmlFor="tree_stand" className="form-label">Tree species</label>
                                    <select
                                        className="form-control"
                                        id="tree_stand"
                                        value={treeStand}
                                        onChange={(e) => setTreeStand(e.target.value)}
                                    >
                                        {treeStandDict[0].value.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </>
                            )}
                            <hr />
                            <div className="mt-3 col-12">
                                <label htmlFor="percentageLandUsed" className="form-label">
                                    Percent of land used for agriculture
                                </label>
                            </div>
                            <div className="col-6">
                                <div className="input-group mb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        min="1"
                                        max="100"
                                        value={percentageLandUsed}
                                        onChange={(e) => setPercentageLandUsed(e.target.value)}
                                        required
                                    />
                                    <span className="input-group-text" id="basic-addon2">%</span>
                                    <div className="invalid-feedback">
                                        Please provide a valid percentage.
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="col-8">
                                <label htmlFor="customRange2" className="form-label"> Percent of carbon retained</label>
                            </div>
                            <div className="col-6">
                                <span>
                                    <div className="input-group mb-3">
                                        <input
                                            type="number"
                                            className="form-control"
                                            min="1"
                                            max="100"
                                            value={sequesteringStat}
                                            onChange={(e) => setSequesteringStat(e.target.value)}
                                            required
                                        />
                                        <span className="input-group-text" id="basic-addon2">%</span>
                                        <div className="invalid-feedback">
                                            Please provide a valid percentage.
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div className="col-12">
                                <input type="range" className="form-range" min="0" max="100" id="customRange2" onChange={(e) => setSequesteringStat(e.target.value)}></input>
                            </div>
                            <hr />
                            <div className="mt-3 col-12">
                                <label htmlFor="years_of_data" className="form-label">
                                    How many years do you want data for?
                                </label>
                            </div>
                            <div className="col-6">
                                <input
                                    type="number"
                                    step="1"
                                    max="40"
                                    min="2"
                                    className="form-control"
                                    id="years_of_data"
                                    value={yearsOfData}
                                    onChange={(e) => setYearsOfData(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please provide a number from 2 to 40.
                                </div>
                            </div>
                            <hr />
                            <div className="text-end">
                                <button type="submit" className="btn btn-success">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
};

export default InputForm;