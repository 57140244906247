const evapoDict = {
    0.125: ['Polar Desert', 'Rain Tundra', 'Rain Forest'],
    0.25: ['Polar Desert', 'Wet Tundra', 'Wet Forest'],
    0.5: ['Polar Desert', 'Moist Tundra', 'Moist Forest'],
    1.0: ['Dry Tundra', 'Dry Scrub', 'Steppe', 'Dry Forest'],
    2.0: ['Desert', 'Desert Scrub', 'Thorn Steppe/Woodland', 'Very Dry Forest'],
    4.0: ['Desert', 'Dry Scrub', 'Thorn Woodland'],
    8: ['Desert', 'Desert Scrub'],
    16: ['Desert'],
};

const percipDict = {
    62.5: ['Polar Desert', 'Desert', 'Dry Tundra'],
    125.0: ['Polar Desert', 'Moist Tundra', 'Dry Scrub', 'Desert Scrub'],
    250.0: ['Polar Desert', 'Wet Tundra', 'Moist Forest', 'Steppe', 'Thorn Steppe', 'Thorn Woodland'],
    500.0: ['Rain Tundra', 'Wet Forest', 'Moist Forest', 'Dry Forest', 'Very Dry Forest'],
    1000.0: ['Rain Forest', 'Wet Forest', 'Moist Forest', 'Dry Forest'],
    2000.0: ['Rain Forest', 'Wet Forest', 'Moist Forest'],
    4000.0: ['Rain Forest', 'Wet Forest'],
    8000.0: ['Rain Forest'],
};

const tempDict = {
    1.5: ['Rain Tundra', 'Wet Tundra', 'Moist Tundra', 'Dry Tundra'],
    3.0: ['Rain Forest', 'Wet Forest', 'Moist Forest', 'Dry Scrub', 'Desert'],
    6.0: ['Rain Forest', 'Wet Forest', 'Moist Forest', 'Steppe', 'Desert Scrub', 'Desert'],
    12.0: ['Rain Forest', 'Wet Forest', 'Moist Forest', 'Dry Forest', 'Thorn Steppe', 'Desert Scrub', 'Desert'],
    24.0: ['Rain Forest', 'Wet Forest', 'Moist Forest', 'Dry Forest', 'Very Dry Forest', 'Thorn Woodland', 'Desert Scrub', 'Desert'],
};

const getClosestLine = (evapTrans, totalPrecip, bioTemp) => {
    let evapTransVal = null;
    let precipVal = null;
    let meanTempVal = null;

    const sortedEvapoKeys = Object.keys(evapoDict).map(Number).sort((a, b) => a - b);
    const sortedPercipKeys = Object.keys(percipDict).map(Number).sort((a, b) => a - b);
    const sortedTempKeys = Object.keys(tempDict).map(Number).sort((a, b) => a - b);

    for (let i = 0; i < sortedEvapoKeys.length; i++) {
        let key = sortedEvapoKeys[i];
        let nextKey = sortedEvapoKeys[i + 1] || Infinity;
        if (key <= evapTrans && evapTrans <= nextKey) {
            evapTransVal = [key];
            if (Math.abs(key - evapTrans) / key <= 0.15) {
                evapTransVal.push('lower');
            } else if (Math.abs(nextKey - evapTrans) / nextKey <= 0.15) {
                evapTransVal.push('upper');
            }
            break;
        }
    }

    for (let i = 0; i < sortedPercipKeys.length; i++) {
        let key = sortedPercipKeys[i];
        let nextKey = sortedPercipKeys[i + 1] || Infinity;
        if (key <= totalPrecip && totalPrecip <= nextKey) {
            precipVal = [key];
            if (Math.abs(key - totalPrecip) / key <= 0.15) {
                precipVal.push('lower');
            } else if (Math.abs(nextKey - totalPrecip) / nextKey <= 0.15) {
                precipVal.push('upper');
            }
            break;
        }
    }

    for (let i = 0; i < sortedTempKeys.length; i++) {
        let key = sortedTempKeys[i];
        let nextKey = sortedTempKeys[i + 1] || Infinity;
        if (key <= bioTemp && bioTemp <= nextKey) {
            meanTempVal = [key];
            if (Math.abs(key - bioTemp) / key <= 0.15) {
                meanTempVal.push('lower');
            } else if (Math.abs(nextKey - bioTemp) / nextKey <= 0.15) {
                meanTempVal.push('upper');
            }
            break;
        }
    }

    if (!evapTransVal || !precipVal || !meanTempVal) {
        return 'No suitable life-zone';
    }

    return [evapTransVal, precipVal, meanTempVal];
};

const getEvapBlended = (evapTrans, percip) => {
    if (typeof evapTrans[evapTrans.length - 1] !== 'string') {
        return null;
    }

    let blended;
    if (evapTrans[1] === 'upper') {
        if (evapTrans[0] > 16) return null;
        blended = evapTrans[0] * 2;
    } else if (evapTrans[1] === 'lower') {
        if (evapTrans[0] < 0.25) return null;
        blended = evapTrans[0] / 2;
    }

    for (let zone of percipDict[percip[0]]) {
        if (evapoDict[blended].includes(zone)) {
            return zone;
        }
    }
};

const getPercipBlended = (evapTrans, percip) => {
    if (typeof percip[percip.length - 1] !== 'string') {
        return null;
    }

    let blended;
    if (percip[1] === 'upper') {
        if (percip[0] > 8000) return null;
        blended = percip[0] * 2;
    } else if (percip[1] === 'lower') {
        if (percip[0] < 125) return null;
        blended = percip[0] / 2;
    }

    for (let zone of evapoDict[evapTrans[0]]) {
        if (percipDict[blended].includes(zone)) {
            return zone;
        }
    }
};

const getBlendedTemperature = (temp, evapTrans, percip) => {
    if (typeof temp[temp.length - 1] !== 'string') {
        return null;
    }

    let blended;
    if (temp[1] === 'upper') {
        if (temp[0] > 36) return null;
        blended = temp[0] * 2;
    } else if (temp[1] === 'lower') {
        if (temp[0] < 1.5) return null;
        blended = temp[0] / 2;
    }

    for (let zone1 of evapoDict[evapTrans[0]]) {
        for (let zone2 of percipDict[percip[0]]) {
            if (tempDict[blended].includes(zone1) && tempDict[blended].includes(zone2) && zone1 === zone2) {
                return zone1;
            }
        }
    }
};

const findLifeZone = (evapTrans, percip, meanTemp) => {
    const keys = getClosestLine(evapTrans, percip, meanTemp);
    let climateType = [];

    if (keys === 'No suitable life-zone') {
        return [keys];
    }

    for (let climate of evapoDict[keys[0][0]]) {
        if (percipDict[keys[1][0]].includes(climate) && tempDict[keys[2][0]].includes(climate)) {
            if (meanTemp >= 24) {
                climateType.push("Tropical " + climate);
            // } else if (meanTemp >= 7 && percip <= 3000) {
            //     climateType.push("Premontane pacific " + climate);
            // } else if (meanTemp >= 7 && percip <= 4000) {
            //     climateType.push("Premontane atlantic " + climate);
            } else if (meanTemp >= 7) {
                climateType.push("Premontane " + climate);
            } else {
                climateType.push(climate);
            }
        }
    }

    if (!climateType.length) {
        return ['No suitable life-zone'];
    }
    return climateType;
};


export { findLifeZone };