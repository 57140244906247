import React from "react";
import Map from "./map";
import './mapDisplay.css';

const MapDisplay = ({
    latitude,
    longitude,
    setCenterPolygon,
    setPolygonArea,
    polygonArea,
    setAreaCoordinates
}) => {
    return (
        <>
            <div className="col-12 col-lg-9" >
                <div className="card mt-3">
                    <Map
                        setPolygonArea={setPolygonArea}
                        setCoordinates={setCenterPolygon}
                        setAreaCoordinates={setAreaCoordinates}
                    />
                    <div className="calculation-box">
                        <p><b>Area:</b><br />{polygonArea} ha</p>
                        <p><b>Coordinates:</b><br />
                            latitude: {latitude ? latitude.toFixed(3) : 0.000}
                            <br />
                            longitude: {longitude ? longitude.toFixed(3) : 0.000}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MapDisplay;