export const tierCropDict = [
    { key: "understory", value: ['Select', 'Cacao', 'Avocado'] },
    { key: "floor", value: ['Select', 'Coffee', 'Pineapple', 'Tobacco', 'Aloe Vera', 'Curcuma'] },
    { key: "canopy", value: ['Select', 'Banana'] },
];

export const monoCropDict = [
    { key: "cash-crop", value: ['Select', 'Sugarcane', 'Rice', 'Corn', 'Ginger', 'Hemp'] },
    { key: "relief-crop", value: ['Select', 'None'] },
];

export const orchardCropDict = [
    { key: "fruit-tree", value: ['Select', 'Mangosteen', 'Mango', 'Orange', 'Lemon', 'Rambutan', 'Guanabana', 'Avocado'] },
    { key: "floor", value: ['Select', 'None', 'Coffee', 'Pineapple', 'Tobacco', 'Aloe Vera', 'Curcuma'] },
];

export const treeStandDict = [
    { key: "trees", value: ['Select', 'Teak', 'Gmelina Arborea', 'Acacia', 'Eucalyptus', 'Cypress', 'Blubell'] }
];