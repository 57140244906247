import React from "react";
import logo from '../images/logo_no_name.png';
import './header.css';

const Header = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-3">
                <a className="navbar-brand" href="https://karbon-x.com/">
                    <img src={logo} id='karbonx-logo' className="mx-2" alt="karbon-x" />
                    <span id='logo-nav'>
                        Karbon-X
                    </span>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Header;